.navbar-dark {
    height: 100px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #000 !important;
    border-color: #B31B1B !important;
}

main {
    margin-top: 135px;
}

.masthead {
    margin-bottom: 40px;
}

.masthead h1 {
   font-size: 60px;
}

.btn-primary {
    color: #fff;
    background-color: #B31B1B;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #861619;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-primary:hover {
    color: #fff;
    background-color: #861619;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary
{
    color: #fff;
    background-color: #861619;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-primary:active:hover, .btn-primary.active:hover, .open>.dropdown-toggle.btn-primary:hover,
.btn-primary:active:focus, .btn-primary.active:focus, .open>.dropdown-toggle.btn-primary:focus,
.btn-primary:active.focus, .btn-primary.active.focus, .open>.dropdown-toggle.btn-primary.focus
{
    color: #fff;
    background-color: #B31B1B;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary
{
    background-image: none;
}

.btn-primary.disabled:hover, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus, .btn-primary[disabled]:focus, fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus, .btn-primary[disabled].focus, fieldset[disabled] .btn-primary.focus
{
    background-color: #861619;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-primary .badge {
    color: #B31B1B;
    background-color: #fff;
}
